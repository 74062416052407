<template>
  <div class="positions-container">
    <h3>Tjing + You === true</h3>
    <p class="large">
      We are looking to expand our core team with a passionate member who will
      be responsible for our backend systems and be a vital part of our ongoing
      journey to taking Tjing to the global market! You will be involved in all
      aspects of the core systems from the instances hosted in AWS to the
      integrations to our partners and maintaining and expanding our core API.
    </p>
    <p class="large extra-space">
      If you are a driven individual who wants to build solutions for disc
      golfers globally and help digitalize the sport then this is for you. You
      will be managing our backend servers hosted in AWS and be leading our
      backend development for current and future features. You will have large
      amounts of freedom when it comes to when you wish to work and how you want
      to implement new ideas. Even though your focus will be on the backend
      systems, we have a PWA for the web and a native app (react-native) as well
      and if you are interested in these fields a full stack role in the team is
      not out of the question.
    </p>

    <p class="large">
      <b> For you to succeed we believe this sounds like you:</b>
    </p>

    <ul>
      <li>
        <p class="large">Passion for your craft and a good communicator.</p>
      </li>
      <li>
        <p class="large">
          Few years experience building APIs or other backend systems.
        </p>
      </li>
      <li>
        <p class="large">
          Some practical experience with Node/Express and GraphQL
        </p>
      </li>
      <li>
        <p class="large">
          Managed projects within AWS, such as ECS, RDS, SNS and more.
        </p>
      </li>
      <li>
        <p class="large">
          An interest in CI/CD, automated tests and workflows.
        </p>
      </li>
      <li>
        <p>
          Comfortable with performant SQL and aggregating data and statistics.
        </p>
      </li>
    </ul>

    <p class="large">
      We are a startup driven by passion and currently no salaries are being
      paid within the team. All revenue goes back into developing the product
      and bringing it to the world. If you are interested in working with us
      let’s discuss what that entails for you.
    </p>
    <p class="large">
      We offer an opportunity to be part of Discgolf history and build the
      things we dream of. We value quality and user experience the most and
      there is always room to test new things and learn, and we have a very open
      culture within the team.
    </p>
    <p class="large extra-space">
      If you are interested please send an email to
      <a href="mailto:marcus@tjing.se">marcus@tjing.se</a> with some details and
      we can have a virtual coffee and talk about discgolf!
    </p>

    <p class="large"><b>Technologies</b></p>
    <div class="labels">
      <span class="status-label strom">Node / Express</span>
      <span class="status-label strom">Mocha</span>
      <span class="status-label strom">Typescript</span>
      <span class="status-label strom">Postgres</span>
      <span class="status-label strom">Redis</span>
      <span class="status-label strom">Ansible</span>
      <span class="status-label strom">Vue</span>
      <span class="status-label strom">React Native</span>
      <span class="status-label strom">Stripe</span>
    </div>

    <div class="end">
      <div class="profile-img">
        <img src="@/assets/img/marcus_greyscale.jpg" alt="Marcus" />
      </div>
      <div class="details">
        <p><b>Marcus Hallbjörner</b></p>
        <p class="small">Co-Founder & Technical Lead</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenPositions",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";

.end {
  margin-top: 48px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
  }

  .profile-img {
    margin-right: 32px;
    min-width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 60px;
    border: 2px solid $fog;
    position: relative;
    box-shadow: 4px 6px 10px 2px $fog;
    transition: all ease 0.3s;

    &:hover {
      border: 2px solid $sleet;
      box-shadow: 4px 6px 10px 2px $sleet;
    }
    img {
      position: absolute;
      width: auto;
      height: 200px;
      top: 8px;
      left: -42px;
    }
  }
  .details {
    width: 100%;
  }
}

.positions-container {
  padding: 0 18px;
  margin-top: 24px;
}

h3 {
  margin-bottom: 18px;
}

p {
  margin-bottom: 12px;
  text-align: justify;

  &.extra-space {
    margin-bottom: 24px;
  }
}

ul {
  padding-left: 18px;
  list-style-type: disc;
  margin-bottom: 24px;

  li {
    margin-bottom: 8px;
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  span {
    margin-bottom: 8px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  p {
    text-align: left;
    max-width: 60%;
    margin-bottom: 24px;

    &.extra-space {
      margin-bottom: 32px;
    }
  }

  .labels {
    justify-content: flex-start;

    > span {
      margin-right: 12px;
    }
  }
}
</style>
